.landing__body__cards{
  display: grid;
  grid-gap: 25px 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  .landing-card {
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    display: grid;
    grid-template-columns: 60% 40%;
    padding-bottom: 25px;
    width: 100%;
  
    &.minimal {
      align-items: center;
      grid-template-columns: 80% 20%;
  
      .landing-card {
        &__right {
          img {
            width: 70%;
            margin-right: 5px;
          }
        }
      }
    }
  
    &__left {
      h2 {
        color: $gold;
        font-family: 'Oswald-Regular';
        font-size: 20px;
        font-weight: normal;
        margin-bottom: 0px;
        margin-top: 0;
        line-height: 1.1
      }
  
      p {
        color: $grey;
        font-family: 'Oswald-Regular';
        margin-top: 6px;
        text-transform: uppercase;
        font-size: 16px;
      }
    }
  
    &__right {
      img {
        float: right;
        width: 100%;
        padding-left: 10px;
      }
    }
  }
}


@media (max-width: 480px) {
  .landing-card {
    padding: 0 0 20px;

    &__left {
      h2 {
        font-size: 20px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}