.guest-services {
  .masthead {
    background-color: #282829;
    position: relative;
    margin-bottom: 35px;
    .page-title{
      width: 50%;
    }
    .guest-services__copy{
      width: 50%;
    }
  }

  &__copy {
    background: $dark-grey;
    color: lighten($grey, 10%);
    font-family: 'CharlotteSansMediumPlain';
    padding: 10px 30px 30px;
    width: 50%;
    

    p {
      margin: 0;
    }
  }

  &__text {
    align-items: center;
    background: $white;
    color: $black;
    display: flex;
    font-family: 'CharlotteSansMediumPlain';
    justify-content: space-between;
    padding: 15px 30px;
    margin-bottom: 5px;    
    position: absolute;
    right: 15px;
    text-decoration: none;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    width: 45%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);

    &__left {
      h3 {
        margin: 0;
        padding: 0;
      }
    }

    &__right {
      display: block;
      max-height: 60px;
    }
  }

  &__nav {
    background: $white;
    font-family: 'Oswald-Regular';
    padding: 30px;
    padding-top: 0;
    display: grid;
    grid-gap: 25px 30px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    a {
      align-items: center;
      color: $gold;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      text-decoration: none;
      text-transform: uppercase;

      .landing-card {
        border-bottom: 1px solid lighten($grey, 20%);
        width: 100%;
        display: grid;
        grid-template-columns: 85% 15%;
        padding: 20px 0;
      }

      img {
        display: block;
        width: 100%;
        margin-top: 9px;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .guest-services{
    .masthead{
      padding-bottom: 50px;
      margin-bottom: 45px;
      .page-title{
        width: 100%;
      }
      .guest-services__copy{
        width: 100%;
      }
      .guest-services__text{
        top: auto;
        bottom: -45px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        min-width: 300px;
      }
    }
  }
}