.concourse-map {
  .page-title{
    padding-bottom: 40px;
  }
  .selectWrapper{
    display: none;
  }
  .selectConcourseMap{    
    width: calc(100% - 60px);
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 15px;
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      width: auto;
      text-align: center;
      li{
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        display: inline-block;
        background-color: #fff;
        padding: 12px 0px;
        margin: 0 15px;
        line-height: 1;
        width: 180px;
        font-weight: 500;
        color: rgba(40, 40, 41, 0.3);
        transition: all 200ms linear;
        cursor: pointer;
        &:hover{
          color: rgba(40, 40, 41, 0.6);          
        }
        &.active{
          color: #282829;
        }
      }
    }
  }
  .seating-chart__body {
    div {
      svg {
        .stroke-miter-limit {
          fill: none;
          stroke-miterlimit:10;
          stroke-width: 4;

          &.stroke {
            &__grey {
              stroke: #ededed;
            }

            &__dark-grey {
              stroke: #3d3d3d;
            }
          }
        }

        .isolation {
          isolation: isolate;
          font-size: 20px;
          fill: #8c8c8c;
          font-family: 'CharlotteSansBoldPlain';
        }

        .twenty-nine {
          font-size: 29.960620880126953px;
          fill: #3d3d3d;
          font-family: 'CharlotteSansBoldPlain'
        }

        .fifteen {
          font-size: 15px;
          fill: #3d3d3d;
          font-family: 'CharlotteSansBoldPlain';
        }

        .text-section {
          font-size: 20px;
          fill: #8c8c8c;
          font-family: 'CharlotteSansBoldPlain';
        }

        .fill {
          &__none {
            fill: none;
          }

          &__greyish {
            fill: #dcdbdb;
          }

          &__grey {
            fill: #f7f7f7;
          }

          &__white {
            fill: #fff;
          }

          &__gold {
            fill: #bfab75;
          }

          &__yellow {
            fill: #ffcf01;
          }

          &__red {
             fill: #ce152d;
          }
        }
      }
    }

    .main-level {
      svg {
        .st0{fill:#EDEDED;}
        .st1 {
          fill: #DCDBDB;

          &.focused {
            fill: #c41230;
          }
        }
        .st2{fill:none;stroke:#BCBCBC;stroke-width:2;stroke-miterlimit:10;}
        .st3{fill:#3D3D3D;}
        .st4{font-family:'CharlotteSansBoldPlain';}
        .st5{font-size:29.9606px;}
        .st6{fill:#FFFFFF;}
        .st7{fill:#003767;}
        .st8{fill:#CE152D;}
        .st9{fill:#C41230;}
        .st10{fill:#FFCF01;}
        .st11{fill:none;}
        .st12{display:none;}
        .st13{display:inline;}
        .st14{fill:none;stroke:#231F20;stroke-miterlimit:10;}
        .st15{display:inline;fill:none;stroke:#231F20;stroke-miterlimit:10;}
        .st16{display:inline;fill:#811C2F;}
        .st17{display:inline;fill:#A358A2;}
        .st18{fill:#F7F7F7;}
        .st19{fill:#8C8C8C;}
        .st20{fill:#214497;}
        .st21{fill:none;stroke:#3D3D3D;stroke-width:4;stroke-miterlimit:10;}
        .st22 {
          fill: #BFAB75;

          &.focused {
            fill: #c41230;
          }
        }
        .st23{fill:#FFFFFF;stroke:#A45A95;stroke-width:1.5;stroke-miterlimit:10;}
        .st24{fill:none;stroke:#A45A95;stroke-width:1.5;stroke-miterlimit:10;}
        .st25{fill:#231F20;}
        .st26{fill:#FFFFFF;stroke:#3F96B4;stroke-width:1.5;stroke-miterlimit:10;}
        .st27{fill:none;stroke:#3F96B4;stroke-width:1.5;stroke-miterlimit:10;}
        .st28{fill:#231F20;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
        .st29{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
        .st30{fill:none;stroke:#D5BA8C;stroke-width:1.5;stroke-miterlimit:10;}
        .st31{stroke:#231F20;stroke-width:0.75;stroke-miterlimit:10;}
        .st32{fill:none;stroke:#9F662D;stroke-width:1.5;stroke-miterlimit:10;}
        .st33{fill:none;stroke:#773DBD;stroke-width:1.5;stroke-miterlimit:10;}
        .st34{fill:#FFFFFF;stroke:#EBE4CF;stroke-width:1.5;stroke-miterlimit:10;}
        .st35{fill:none;stroke:#EBE4CF;stroke-width:1.5;stroke-miterlimit:10;}
        .st36{fill:none;stroke:#9F2214;stroke-width:1.5;stroke-miterlimit:10;}
        .st37{fill:#1059B2;}
        .st38{fill:#2EB1EC;}
        .st39{fill:#4F2683;}
      }
    }

    .upper-level {
      svg {
        .st0{fill:#EDEDED;}
        .st1 {
          fill: #DCDBDB;

          &.focused {
            fill: #c41230;
          }
        }
        .st2{fill:none;stroke:#EDEDED;stroke-width:4;stroke-miterlimit:10;}
        .st3{fill:none;stroke:#BCBCBC;stroke-width:2;stroke-miterlimit:10;}
        .st4{fill:#FFFFFF;}
        .st5{fill:#003767;}
        .st6{fill:#CE152D;}
        .st7{fill:#C41230;}
        .st8{fill:#FFCF01;}
        .st9{fill:none;stroke:#3D3D3D;stroke-width:4;stroke-miterlimit:10;}
        .st10{fill:#F7F7F7;}
        .st11{fill:#8C8C8C;}
        .st12{font-family:'CharlotteSansBoldPlain';}
        .st13{font-size:30.0847px;}
        .st14{fill:#214497;}
        .st15 {
          fill: #BFAB75;

          &.focused {
            fill: #c41230;
          }
        }
        .st16{fill:#FFFFFF;stroke:#9F2521;stroke-width:1.5;stroke-miterlimit:10;}
        .st17{fill:none;stroke:#9F2521;stroke-width:1.5;stroke-miterlimit:10;}
        .st18{fill:#231F20;}
        .st19{fill:#FFFFFF;stroke:#A45A95;stroke-width:1.5;stroke-miterlimit:10;}
        .st20{fill:none;stroke:#A45A95;stroke-width:1.5;stroke-miterlimit:10;}
        .st21{fill:#FFFFFF;stroke:#003595;stroke-width:1.5;stroke-miterlimit:10;}
        .st22{fill:none;stroke:#003595;stroke-width:1.5;stroke-miterlimit:10;}
        .st23{fill:#231F20;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
        .st24{fill:#FFFFFF;stroke:#FF6C0E;stroke-width:1.5;stroke-miterlimit:10;}
        .st25{fill:none;stroke:#FF6C0E;stroke-width:1.5;stroke-miterlimit:10;}
        .st26{fill:#FFFFFF;stroke:#93D500;stroke-width:1.5;stroke-miterlimit:10;}
        .st27{fill:none;stroke:#93D500;stroke-width:1.5;stroke-miterlimit:10;}
        .st28{stroke:#231F20;stroke-miterlimit:10;}
        .st29{fill:none;stroke:#D5BA8C;stroke-width:1.5;stroke-miterlimit:10;}
        .st30{stroke:#231F20;stroke-width:0.75;stroke-miterlimit:10;}
        .st31{fill:none;stroke:#9F662D;stroke-width:1.5;stroke-miterlimit:10;}
        .st32{fill:none;stroke:#773DBD;stroke-width:1.5;stroke-miterlimit:10;}
        .st33{fill:none;stroke:#9F2214;stroke-width:1.5;stroke-miterlimit:10;}
        .st34{fill:none;stroke:#C1C5C8;stroke-width:1.5;stroke-miterlimit:10;}
        .st35{fill:#231F20;stroke:#231F20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st36{fill:none;stroke:#FED925;stroke-width:1.5;stroke-miterlimit:10;}
        .st37{fill:#FFFFFF;stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
        .st38{stroke:#231F20;stroke-width:0.5;stroke-miterlimit:10;}
        .st39{fill:#3D3D3D;}
        .st40{font-size:29.9606px;}
        .st41{fill:#1059B2;}
        .st42{fill:#2EB1EC;}
        .st43{fill:none;stroke:#002F87;stroke-width:1.5;stroke-miterlimit:10;}
        .st44{fill:#54B948;stroke:#54B948;stroke-width:0.5;stroke-miterlimit:10;}
        .st45{fill:#00A160;stroke:#00A160;stroke-width:0.5;stroke-miterlimit:10;}
        .st46{fill:none;stroke:#231F20;stroke-width:1.5;stroke-miterlimit:10;}
        .st47{fill:#231F20;stroke:#231F20;stroke-width:0.25;stroke-miterlimit:10;}
      }
    }

    .suite-level {
      svg {
        .st0{fill:#EDEDED;}
        .st1 {
          fill: #DCDBDB;

          &.focused {
            fill: #c41230;
          }
        }
        .st2{fill:none;stroke:#BCBCBC;stroke-width:2;stroke-miterlimit:10;}
        .st3{fill:#FFFFFF;}
        .st4{fill:#003767;}
        .st5{fill:#CE152D;}
        .st6{fill:#C41230;}
        .st7{fill:#FFCF01;}
        .st8{fill:#3D3D3D;}
        .st9{font-family:'CharlotteSansBoldPlain';}
        .st10{font-size:15px;}
        .st11{font-size:29.9606px;}
        .st12{fill:#F7F7F7;}
        .st13{fill:none;stroke:#EDEDED;stroke-width:4;stroke-miterlimit:10;}
        .st14{fill:#034C82;}
        .st15{font-size:20px;}
        .st16{fill:#8C8C8C;}
        .st17{fill:none;stroke:#3D3D3D;stroke-width:4;stroke-miterlimit:10;}
        .st18{fill:#DCDBDB;stroke:#EDEDED;stroke-width:4;stroke-miterlimit:10;}
        .st19 {
          fill: #BFAB75;

          &.focused {
            fill: #c41230;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .concourse-map{
    .selectWrapper{
      display: block;
    }
    .selectConcourseMap{ 
      display: none;
    }
  }  
}