.modal {
  &__message {
    background-color: rgba(40, 40, 41, 0.5);
    height: 100%;
    position: absolute;
    font-family: 'CharlotteSansMediumPlain';
    width: 100%;
    max-width: 800px;
    z-index: 9999;
    top: 0;
    padding: 30px;

    &__box {
      background: $white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 30px;
      margin-top: 30%;
    }

    &__close {
      border: 2px solid $dark-grey;
      cursor: pointer;
      text-align: center;
      width: 100px;
      padding: 10px 15px;
      margin: 20px auto 0;
    }
  }

  &__info {
    background-color: $dark-grey;
    color: $white;
    height: 100%;
    position: absolute;
    font-family: 'CharlotteSansMediumPlain';
    width: 100%;
    overflow-y: scroll;
    max-width: 800px;
    z-index: 9999;
    top: 0;
    padding: 30px;

    h2 {
      font-family: 'Oswald-Regular';
      margin-top: 0;
      text-transform: uppercase;
    }

    &__close {
      cursor: pointer;
      float: right;
      height: 40px;
      width: 40px;
    }
  }

  &__seating {
    &__level {
      border-bottom: 1px solid $grey;
      font-family: 'Oswald-Regular';
      padding-bottom: 30px;

      img {
        max-height: 15px;
        margin-right: 15px;
      }
    }

    &__proximity {
      border-bottom: 1px solid $grey;
      font-family: 'CharlotteSansMediumPlain';
      margin: 30px 0;
      padding-bottom: 30px;

      &--section {
        margin-bottom: 25px;
        padding: 0 10px;
      }

      &--specific {
        display: block;
        padding-top: 10px;
        padding-left: 0;

        &::before {
          content: "•"; 
          color: $white;
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }

    &__footer {
      align-items: center;
      background: $white;
      color: $black;
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      text-decoration: none;

      &__copy {
        font-family: 'CharlotteSansMediumPlain';

        div {
          text-transform: uppercase;
        }
      }

      img {
        display: block;
        max-height: 40px;
      }
    }
  }

  &__filter {
    background-color: $white;
    color: $black;
    height: 100%;
    position: absolute;
    font-family: 'CharlotteSansMediumPlain';
    width: 100%;
    max-width: 800px;
    z-index: 9999;
    top: 0;
    padding: 30px;

    h2 {
      font-family: 'Oswald-Regular';
      margin-top: 0;
      text-align: center;
      text-transform: uppercase;
    }

    &__option {
      margin-bottom: 30px;
      width: 100%;
      display: block;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
          ~ .modal__filter__check-box {
            background-color: $gold;
            -webkit-transform: rotate(0deg) scale(1);
            -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
            opacity: 1;
            border: 2px solid $gold;

            &::after {
              -webkit-transform: rotate(45deg) scale(1);
              -ms-transform: rotate(45deg) scale(1);
              transform: rotate(45deg) scale(1);
              opacity: 1;
              left: 6px;
              top: 1px;
              width: 6px;
              height: 12px;
              border: solid $white;
              border-width: 0 2px 2px 0;
              background-color: transparent;
              border-radius: 0;
            }
          }
        }
      }
    }

    &__check-box {
      position: absolute;
      right: 30px;
      height: 24px;
      width: 24px;
      background-color: transparent;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid $gold;

      &::after {
        position: absolute;
        content: "";
        left: 12px;
        top: 12px;
        height: 0px;
        width: 0px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
        opacity: 1;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
      }
    }

    &__buttons {
      border-top: 1px solid lighten($grey, 30%);
      padding: 30px 0;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }

    &__button {
      border: 1px solid $black;
      cursor: pointer;
      width: 45%;
      text-align: center;
      padding: 15px 0;

      &.dark {
        background: $black;
        color: $white;
      }
    }
  }
}