@font-face {
  font-family: 'CharlotteSansBoldPlain';
  src: url('/fonts/CharlotteSansBoldPlain.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'CharlotteSansMediumPlain';
  src: url('/fonts/CharlotteSansMediumPlain.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-Black';
  src: url('/fonts/Merriweather-Black.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-BlackItalic';
  src: url('/fonts/Merriweather-BlackItalic.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-Bold';
  src: url('/fonts/Merriweather-Bold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-BoldItalic';
  src: url('/fonts/Merriweather-BoldItalic.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-Italic';
  src: url('/fonts/Merriweather-Italic.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-Light';
  src: url('/fonts/Merriweather-Light.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-LightItalic';
  src: url('/fonts/Merriweather-LightItalic.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Merriweather-Regular';
  src: url('/fonts/Merriweather-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Oswald-Bold';
  src: url('/fonts/Oswald-Bold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Oswald-Light';
  src: url('/fonts/Oswald-Light.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Oswald-Regular';
  src: url('/fonts/Oswald-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}
