.header {
  margin: 0 auto;
  position: fixed;
  top: 0;
  width: 800px;
  z-index: 9;

  &__top {
    background: rgba(0, 0, 0, .95);
    color: $white;
    padding: 8px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    max-width: 130px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__nav {
    background: $dark-grey;
    font-family: 'Oswald-Regular';
    text-align: center;
    position: fixed;
    min-height: 100vh;
    width: 800px;
    padding: 40px 30px;
    z-index: 9;

    a {
      color: $grey;
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 24px;
      margin-bottom: 25px;

      &.active {
        color: $gold;
      }
    }

    &__social {
      margin: 40px auto;

      a {
        display: inline;
      }

      img {
        max-height: 30px;
        max-width: 30px;
        margin: 0 10px;
      }
    }
  }
}

#nav-burger {
  cursor: pointer;
  height: 25px;
  // margin: 0 auto;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 25px;

  span {
    background: $gold;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    width: 50%;

    &:nth-child(even) {
      left: 49%;
    }

    &:nth-child(odd) {
      left: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 8px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 16px;
    }
  }

  &.open {
    span {
      &:nth-child(1),
      &:nth-child(6) {
        transform: rotate(45deg);
      }

      &:nth-child(2),
      &:nth-child(5) {
        transform: rotate(-45deg);
      }

      &:nth-child(1) {
        left: 0;
        top: 7px;
      }

      &:nth-child(2) {
        left: calc(50% - 3px);
        top: 7px;
      }

      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }

      &:nth-child(4) {
        left: 100%;
        opacity: 0;
      }

      &:nth-child(5) {
        left: 0;
        top: 17px;
      }

      &:nth-child(6) {
        left: calc(50% - 3px);
        top: 17px;
      }
    }
  }
}

@media(max-width: 800px) {
  .header {
    width: 100%;
    
    &__nav {
      width: 100%;
    }
  }
}