.carousel {
  text-align: center;
  
  &.absolute {
    position: absolute;
  }
  
  &__body {
    background: rgba(0, 0, 0, .5);
    position: relative;
    z-index: 4;
  }

  &__top {
    color: $gold;
    font-family: 'Oswald-Regular';
    font-size: 24px;
    margin-top: 0;
    padding: 20px 20px 0;
    text-transform: uppercase;

    .white {
      color: $white;
    }
  }

  &__image {
    width: 100%;

    img {
      display: block;
      margin: 0 auto;
      width: 80%;
    }
  }

  &__copy {
    color: $white;
    font-family: 'Oswald-Regular';
    margin: 20px auto 10px;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &__date {
    color: $gold;
    font-family: 'Oswald-Bold';
    font-size: 28px;
    font-weight: bold;
    margin: 0 auto 10px;
    text-align: left;
    text-transform: uppercase;
  }

  &__indicators {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: 30px auto 0;
  }

  &__indicator {
    cursor: pointer;
    background: $grey;
    height: 5px;
    display: block;
    width: 25%;
    margin-bottom: 30px
  }
}

.home_slider {
  margin-bottom: 0 !important;

  .homeSlide {
    .content {
      padding: 10px 15px;
      background-size: cover;
      background-position: top center;
      padding-bottom: 70px;

      h1 {
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        color: #bfab75;
        // font-size: 20px;

        strong {
          color: #fff;
          font-weight: 500;
        }
      }

      .slideImage {
        position: relative;
        padding: 10px 15px;

        &.slideMobile{
          display: none;
        }

        img {
          width: 100%;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        }

        .liveEvent {
          position: absolute;
          top: 30px;
          left: 35px;
          display: block;
          width: calc(100% - 70px);
          font-family: 'Charlotte Sans';

          a {
            background-color: rgba(255, 255, 255, 0.5);
            display: block;
            text-decoration: none;
            line-height: 1;
            font-size: 14px;
            letter-spacing: 1.3px;
            text-transform: uppercase;
          }

          span {
            color: #fff;
            text-decoration: none;
            vertical-align: -2px;
          }

          .badge {
            font-size: 14px;
            background-color: #811c2f;
            color: #fff;
            font-family: 'Charlotte Sans';
            text-transform: uppercase;
            letter-spacing: 1px;
            width: 46px;
            display: inline-block;
            text-align: center;
            height: 26px;
            line-height: 26px;
            margin-right: 2px;
          }

          i {
            position: absolute;
            right: 6px;
            font-size: 12px;
            top: 7px;
            color: #fff;
          }
        }
      }

      .slideFooter {
        padding: 0 35px;

        p {
          color: #fff;
          font-size: 21px;
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 600;
        }

        h2 {
          font-size: 25px;
          line-height: 35px;
          color: #bfab75;
          font-weight: 600;
          text-transform: uppercase;
        }

        &.sports {
          padding: 0 25px;

          .slideFooterLeft {
            display: inline-block;
            width: 70%;
            border-right: solid 2px #ccc;

            .team {
              position: relative;
              color: #535353;
              font-weight: 500;

              .teamLogo {
                display: inline-block;
                width: 30px;
                height: 30px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 5px;

                &.tcu {
                  background-image: url(/img/teams/1200px-TCU_Horned_Frogs_logo.png);
                }

                &.usc {
                  background-image: url(/img/teams/USC_Trojans_logo.png);
                }
              }

              .teamName {
                vertical-align: 7px;
              }

              .teamScore {
                float: right;
                vertical-align: 7px;
                line-height: 32px;
                margin-right: 12px;
              }

              &.winner:after {
                content: "\f0d9";
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                position: absolute;
                right: 0;
                top: 50%;
                color: #811c2f;
                transform: translateY(-50%);
              }
            }
          }

          .slideFooterRight {
            display: inline-block;
            width: 30%;
            // float: right;
            text-align: center;
            padding: 5px 0;

            p {
              font-family: 'Oswald';
              font-size: 18px;
              color: #535353;
              line-height: 1.1;
            }

            &.liveScore p {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: 35px;

    li {
      width: 50px;
      height: 4px;

      button {
        width: 50px;
        height: 4px;
        padding: 0;
        background: rgba(255, 255, 255, 0.3);

        &::after,
        &::before {
          display: none;
        }
      }

      &.slick-active button {
        background: rgba(255, 255, 255, 1);
      }
    }
  }
}

@media (max-width: 400px) {
  .home_slider {
    .homeSlide {
      .content {
        .slideFooter {
          padding: 0 15px;
        }
      }
    }

    .slick-dots {
      bottom: 45px;
    }
  }

  .carousel {
    &__top {
      font-size: 20px;
      padding-top: 0;
      margin-bottom: 0;
    }

    &__copy {
      margin-top: 0;
    }

    &__date {
      font-size: 24px;
    }
  }

  .home_slider .homeSlide .content .slideImage {
    &.slideMobile{
      display: block;
    }
    &.slideTablet{
      display: none;
    }
  }
}