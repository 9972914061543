.events {
  .masthead {
    img {
      margin-bottom: -5px;
      width: 100%;
    }
  }
  .eventTitle {
    background-color: #212121;
    padding: 20px;
    position: relative;
    padding-right: 200px;

    h1 {
      color: $white;
      font-family: 'Oswald-Regular';
      font-size: 22px;
      font-weight: 500;
      margin: 0;
    }
    
    h2 {
      color: $gold;
      font-family: 'Oswald-Regular';
      font-size: 32px;
      font-weight: 600;
      margin: 0;
      text-transform: uppercase;
    }
    .ticketsBtnTablet{
      background-color: #ffffff;
      color: #000;
      position: absolute;
      top: 50%;
      right: 25px;
      padding: 0px 25px;
      transform: translateY(-50%);
    }
  }

  .eventContainer{
    border: solid 1px #eee;
    .eventContent {
      background-color: $white;
      max-height: 400px;
      overflow: hidden;
      padding: 20px 30px;
      position: relative;
      transition: cubic-bezier(0.775, 0, 0.211, 1) 700ms all;
      font-family: 'CharlotteSansMediumPlain';
      display: inline-block;
      width: calc(100% - 245px);
  
      .btn {
        margin-bottom: 20px;
        width: 100%;
      }
  
      .readMore {
        bottom: 0;
        display: block;
        height: 105px;
        left: 0;
        pointer-events: all;
        position: absolute;
        transition: cubic-bezier(0.775, 0, 0.211, 1) 700ms all;
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 40%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        width: 100%;
  
        span {
          bottom: 15px;
          left: 30px;
          position: absolute;
        }
      }
  
      &.full {
        max-height: 1500px;
  
        .readMore {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    .eventsNav{
      display: inline-block;
      margin-left: 10px;
      box-shadow: none;
      vertical-align: top;
      a.nav-item {
        color: $gold;
        display: block;
        font-family: 'Oswald-Regular';
        font-size: 19px;
        padding: 18px 0;
        text-decoration: none;
        
        img {
          display: inline-block;
          margin-right: 12px;
        }
      }
    }
    .ticketsBtnMobile{
      display: none;
    }
  }

  .seperator{
    width: 100%;
    height: 7px;
    box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    background-color: #f3f3f3;
    display: none;
  }
}

.ohterEventSlider {
  background: $white;
  font-family: 'Oswald';
  padding: 20px 30px;
  text-align: center; 
  border: solid 1px #eee;

  h3 {
    color: #282829;
    font-family: 'Oswald-Regular';
    font-size: 24px;
    margin: 0;
    padding: 25px 0;
    text-transform: uppercase;
    text-align: left;
  }

  .slick-slide {
    font-family: 'Oswald-Regular';
    padding: 0 10px;
    text-align: left;

    img {
      width: 100%;
    }

    h2 {
      color: $gold;
      font-size: 19px;
      font-weight: normal;
      line-height: 1.1;
      margin-bottom: 0;
      margin-bottom: 5px;
      margin-top: 10px;
      text-transform: capitalize;
    }

    p {
      color: #8a8a8d;
      font-size: 14px;
      margin: 0;
      text-transform: uppercase;
    }
    
    a{
      text-decoration: none;
    }
  }

  .btn {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 700px) {
  .events{
    .eventTitle {
      padding: 20px;
      .ticketsBtnTablet{
        display: none;
      }
    }
    .eventContainer{
      .ticketsBtnMobile{
        display: block;
      }
      .eventContent{
        display: block;
        width: 100%;
      }
      .eventsNav{
        padding: 10px 30px;
        display: block;
      }
      .seperator{
        display: block;
      }
    }
  }    
  .ohterEventSlider{
    box-shadow: none;
    padding-top: 0;
  }
}