.seating-chart {
  &__canvas {
    padding: 50px 30px 20px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__sections {
    max-height: 300px;
    overflow-x: scroll;
  }

  &__body {
    div {
      svg {
        g {
          text {
            fill: $white;
            font-family: 'CharlotteSansMediumPlain';
            font-size: 14px;
          }

          &.small-section {
            text {
              font-size: 8px !important;

              &.small-section {
                &__number {
                  fill: $grey;
                }
              }
            }

            &.focused {
              text {
                &.small-section {
                  &__number {
                    fill: #c41230;
                  }
                }
              }
            }
          }

          &.focused {
            rect,
            polygon,
            path {
              fill: #c41230;
            }
          }

          rect,
          polygon,
          path {
            fill: $grey;
          }

          &#hockey_rink {
            path,
            rect {
              fill: none !important;
              stroke: $black;
            }
            
            text {
              stroke: $black;
              font-size: 12px;
            }
          }

          &#STAGE,
          &#mix {
            rect {
              fill: none;
              stroke: $black;
            }

            text {
              fill: $black;
            }
          }

          &#UNAVAILABLE {
            polygon,
            rect,
            path {
              fill: none;
            }
          }
        }

        #handicapped {
          path {
            fill: $white;
            stroke: $blue;
          }

          g {
            g {
              circle {
                fill: $white;
              }
            }
          }
        }

        #court {
          rect {
            fill: $white;
            stroke: $black;
          }

          circle {
            stroke: $black;
            fill: rgba(0,0,0,0);
          }

          path {
            fill: none;
            stroke: $black;
          }

          line {
            stroke: $black;
            fill: $black;
          }

          text {
            fill: $black;
          }
        }

        #stage {
          rect {
            fill: $white;
            stroke: $black;
          }

          text {
            fill: $black;
          }
        }
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__search {
    appearance: none;
    border: 0;
    border-bottom: 1px solid lighten($grey, 30%);
    border-radius: 0;
    border-top: 1px solid lighten($grey, 30%);
    width: 100%;
    padding: 15px 20px;
    font-size: 18px;
    margin: 0;

    &-box {
      display: flex;

      .clearSearch {
        img {
          display: inline-block;
          max-height: 15px;
          max-width: 15px;
          padding-left: 0;
        }
      }

      span {
        align-items: center;
        border: 1px solid lighten($grey, 30%);
        border-right: 0;
        cursor: pointer;
        display: flex;
        padding: 0 20px;

        img {
          max-height: 20px;
          padding-left: 10px;
        }
      }

      
    }

    &:focus {
      outline: none;
    }
  }

  &__section {
    border-bottom: 1px solid lighten($grey, 30%);
    font-family: 'CharlotteSansMediumPlain';
    padding: 15px 20px;

    &.active {
      background: $pale-red;
      color: $red;
    }
  }
}

.selectWrapper{
  width: calc(100% - 60px);
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  margin-top: -10px;
  margin-bottom: 15px;

  #selector {
    div {
      border-radius: 0;
      box-shadow: none;    
      min-height: 40px;
      line-height: 40px;

      .css-tj5bde-Svg{
        margin-top: 3px;
      }

      input {
        &:focus {
          outline: none;
        }
      }
    }    
    
  }
}
