.upcoming {
  &__events {
    padding: 30px;
  }
}

@media(max-width: 400px) {
  .upcoming {
    &__events {
      padding: 20px;
    }
  }
}