.footer {
  background: url('/img/footer-bg.jpg');
  background-size: cover;
  color: $white;
  font-family: 'CharlotteSansMediumPlain';
  padding: 30px 30px 40px;
  text-align: center;
  display: grid;
  grid-gap: 20px 15px;
  grid-template-columns: repeat(auto-fit, minmax(193px, 1fr));

  &__logo {
    padding: 10px 20px 20px;

    img {
      width: 200px;
      max-width: 100%;
    }
  }

  &__phone,
  &__address {
    padding-bottom: 20px;
  }

  &__social {
    padding-top: 20px;

    img {
      max-height: 30px;
      max-width: 30px;
      margin: 0 15px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .footer{
    display: block;
  }
}