.landing {
  background: $dark-grey;
  font-size: 20px;

  &__body {
    padding: 20px 30px 30px;

    h2 {
      color: $white;
      font-family: 'CharlotteSansMediumPlain';
      font-size: 18px;
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 35px;
    }

    &__cards {
      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 400px) {
  .landing {
    &__body {
      padding: 15px;
    }
  }
}
