.fp-slidesContainer{
  transition: none !important;
}

.fullpage-wrapper {
  width: 100%;
  max-width: 800px;
  margin: auto;
  font-family: 'Oswald-Regular';

  .animated {
    opacity: 0;
  }

  h2 {
    color: $gold;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
  }

  p {
    margin: 0;
    font-family: 'CharlotteSansMediumPlain';
    font-size: 18px;
  }

  .welcome {
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    position: relative;

    .textBox {
      width: 87%;
      margin: auto;
      
      h2 {
        font-family: 'Oswald-Regular';
        font-size: 60px;
        font-weight: normal;
        color: $white;
        text-transform: uppercase;
        line-height: 80px;
        margin-bottom: 0;
      }

      p {
        color: $white;
        font-weight: normal;
        font-size: 33px;
        padding-top: 15px;
        line-height: 35px;
        margin-top: 0;
      }
    }

    .swipeBox {
      position: absolute;
      bottom: 18px;
      text-align: center;
      width: auto;
      right: 0;
      padding-right: 35px;
      max-width: 50%;

        p {
          color: $white;
          font-size: 17px;
          padding-top: 5px;
          display: inline-block;
          float: left;
          margin-right: 20px;
        }

        a.arrowBox {
          border: 1px solid #bfab75;
          width: 25px;
          height: 25px;
          transform: rotate(-45deg);
          transform-origin: center center;
          display: inline-block;
          margin: 0;
          float: right;

          span::before {
            border-style: solid;
            border-width: 3px 3px 0 0;
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            left: -1px;
            position: relative;
            top: 7px;
            vertical-align: top;
            color: #fff;
          }
        }
      }
    }

    .about {
      background: #282829;
      height: 100vh;
      box-sizing: border-box;
      position: relative;

      .about_top {
        height: 45%;
      }

      .about_bottom {
        height: 55%;
        background: url(/img/about_bg.svg) #e2e2e2;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;

        img {
          position: absolute;
          width: 75%;
          left: 50%;
          transform: translateX(-50%);
          top: 50%;
          transition: all .5s ease-out;
          max-width: 600px;

          &.animateIt {
            top: 100%;
            width: 100%;
            max-width: 800px;
          }
        }
      }

      h2.abt {
        padding: 75px 0 10px 25px;
      }

      p {
        color: #8a8a8d;
        padding: 0 25px 10px 25px;
      }
    }

    .multiPurpose {
      text-align: center;
      position: relative;
      height: 100vh;

      .masthead {
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        width: 100%;
      }

      .left {
        width: auto;
        display: inline-block;
        vertical-align: top;
        margin: 25px 20px 0 0;

        img {
          margin-bottom: 40px;
          width: auto;
          display: block;
        }
      }

      .right {
        width: 58%;
        display: inline-block;
        vertical-align: top;
        margin: 25px 0px;
        text-align: left;
      }
    }

    .innerBg {
      width: 100%;
      height: 100vh;
      background-position: center;
      background-size: cover;
      position: relative;

      .textBox {
        width: 75%;
        margin: auto;

        &.fullHeight {
          height: 100%;

          div {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }            

        h1 {
          font-size: 43.2px;
          font-weight: normal;
          color: $white;
          text-transform: uppercase;
          line-height: 50px;
        }

        p {
          color: $white;
          font-weight: normal;
          font-size: 18px;
          padding-top: 15px;
          line-height: 22px;
        }
      }
    }

    .conclusionBg {
      .textBox {
        padding-bottom: 75%;
      }

      img {
        display: inline-block;
        margin-right: 30px;
        max-width: 30px;
        max-height: 25px;
        margin-top: 50px;
      }
    }

    .soundBg {
      h2 {
        padding-top: 38%;
        text-align: center;
      }
    }

    .carBg {
      h2 {
        padding-top: 50%;
        text-align: center;
        text-transform: none;
      }
    }

    .throwBg {
      h2 {
        padding-top: 38%;
        text-align: center;
      }
    }
}

.fp-controlArrow {
  display: none;
}

.slideContainer {
  float: left;
  width: 100%;

  .placeholder {
    opacity: 0;
    visibility: hidden;
    margin-top: -100px;
  }

  .slide_2_image {
    display: none;
  }

  .imageContainer {
    img{
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
    }
  }
  
  .contentSlider {
    height: 250px;
    padding: 0 15px;

    .sliderNav {
      display: inline-block;
      max-width: 60px;
      margin-right: 25px;

      .star {
        height: 50px;
        width: 50px;
        display: block;
        background-image: url(/img/star2.png);
        background-size: contain;
        margin-bottom: 20px;
        transition: all 200ms linear;

        &.active {
          background-image: url(/img/star1.png);
        }
      }
    }

    .slides{
      display: inline-block;
      width: calc(100% - 130px);
      vertical-align: top;
    }

    .slideContent{
      display: none;
      text-align: left;

      p {
        color: #8a8a8d;
        line-height: 25px;
        font-size: 18px;
        margin-bottom: 20px;

        strong {
          color: #811c2f;
          font-weight: normal;
        }
      }

      h2 {
        padding: 0px 0 18px 0;
      }
    }
  }
}

.sectionOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  background: rgba(136, 136, 136, 1);
  background: -moz-linear-gradient(top, rgba(136, 136, 136, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(136, 136, 136, 1)), color-stop(50%, rgba(255, 255, 255, 1)), color-stop(70%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
  background: -webkit-linear-gradient(top, rgba(136, 136, 136, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(top, rgba(136, 136, 136, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: -ms-linear-gradient(top, rgba(136, 136, 136, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(136, 136, 136, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#888888', endColorstr='$whitefff', GradientType=0);

  h1 {
    font-size: 24px;
    color: $gold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &.concertOverlay {
    .concertBgImage {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
    }

    h1 {
      white-space: nowrap;
    }
  }

  &.displayOverlay {
    img {
      top: 30%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    h1 {
      top: calc(30% + 130px);
      left: 50%;
      transform: translateX(-50%);
      width: 225px;
      line-height: 1.3;
    }
  }

  &.artdecoOverlay {
    background-image: url(/img/pattern-bg.png);
    background-position: center center;
    background-size: cover;
  }

  &.houseOverlay {
    background-position: center center;
    background-size: cover;
  }
}

.heightFix {
  padding-top: 40%;
}

.aboutPagination {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  padding: 18px;
  box-sizing: border-box;
  text-align: left !important;
  transition: all 300ms linear;

  .aboutProgressContainer {
    width: 50%;
    display: inline-block;

    .aboutProgressBar {
      background-color: rgba(255, 255, 255, 0.8);
      height: 5px;
      width: calc(100% - 50px);
      display: inline-block;
      margin-left: 15px;
      vertical-align: middle;
      position: relative;

      .progress {
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        background-color: #bfab75;
        transition: all 200ms linear;
      }
    }
  }

  span,
  div {
    color: $white
  }

  a {
    display: none;
    color: $white;
    float: right;
    font-family: 'CharlotteSansMediumPlain';
    font-weight: 500;
    font-style: normal;
    font-size: 17px;
    text-decoration: none;
    vertical-align: middle;

    img {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      max-height: 20px;
      margin-left: 10px;
    }
  }

  &.visible {
    background: rgba($color: #282829, $alpha: 0.8);
  }
}

@media only screen and (max-width: 700px) {
  .fullpage-wrapper .welcome .swipeBox{
    max-width: none;
    padding: 0;
    width: 100%;

    a.arrowBox {
      float: none;
      margin: auto;
      display: block;
    }

    p {
      display: block;
      margin-top: 10px;
      float: none;
    }
  }

  .aboutPagination .aboutProgressContainer {
    width: auto;
  }

  .aboutPagination .aboutProgressContainer .aboutProgressBar {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .fullpage-wrapper .welcome .textBox h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .fullpage-wrapper .welcome .textBox p {
    font-size: 24px;
    line-height: 25px;
  }

  .fullpage-wrapper .about .about_top {
    height: 60%;
  }

  .fullpage-wrapper .about .about_bottom {
    height: 40%;
  }

  .fullpage-wrapper .about .about_bottom img {
    top: 63%;
  }

  .fullpage-wrapper .about p {
    font-size: 16px;
  }

  .fullpage-wrapper h2 {
    font-size: 24px;
  }

  .slideContainer .contentSlider .slideContent p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 330px) {
  .fullpage-wrapper .about p {
    font-size: 14px;
  }

  .fullpage-wrapper .about .about_top {
    height: 55%;
  }

  .fullpage-wrapper .about .about_bottom img {
    top: 58%;
    width: 75%;
  }

  .fullpage-wrapper h2 {
    font-size: 18px;
  }

  .slideContainer .contentSlider .slideContent p {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 10px;
  }

  .fullpage-wrapper .innerBg .textBox p {
    font-size: 16px;
    line-height: 17px;
  }

  .aboutPagination a{
    font-size: 15px;
  }
}