.ada-services {
  &__copy {
    font-family: 'CharlotteSansMediumPlain';
    padding: 30px;

    p {
      a {
        color: $gold;
        text-decoration: none;
      }
    }

    &__bottom {
      padding: 30px;

      h3 {
        font-family: 'CharlotteSansMediumPlain';
        text-transform: uppercase;
      }

      p {
        font-family: 'CharlotteSansMediumPlain';
      }

      a {
        color: $gold;
        text-decoration: none;
      }
    }
  }

  &__info {
    padding: 0 30px;
  }
}