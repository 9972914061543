@import './colors';
@import './fonts';
@import './animate';

* {
  box-sizing: border-box;
}

@import './containers';
@import './components/';

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
  overscroll-behavior-y: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 61px;
  
  &.fixedHeader{
    padding-top: 0;

    .header__top{
      background-color: transparent;
    }
  }
}

.loading {
  background: $dark-grey;
  color: $white;
  min-height: 100vh;
  padding-top: 60px;
  text-align: center;

  img {
    margin-bottom: 30px;
  }
}

.btn {
  -moz-user-select: none;
  -ms-user-select: none;
  border-radius: 0;
  display: inline-block;
  font-family: 'CharlotteSansMediumPlain';
  font-size: 1rem;
  height: 45px;
  letter-spacing: 0.5px;  
  line-height: 40px;
  padding: 0 .75rem;
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; 
  user-select: none;
  white-space: nowrap;

  &.btn-primary {
    background-color: #282829;
    border: solid 1px #282829;      
    color: $white;
  }

  &.btn-outline-primary {
    border: solid 1px #282829; 
    color: #282829;
  }

  &.btn-block {
    display: block;
  }
}

