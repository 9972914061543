.info-expander {
  border-bottom: 1px solid $gold;

  &__title {
    background: lighten($grey, 40%);
    cursor: pointer;
    font-family: 'CharlotteSansMediumPlain';
    padding: 15px;
    text-transform: uppercase;
    width: 100%;

    &__chevron {
      float: right;
      padding-right: 10px;
      margin-top: 3px;

      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: .45em;
        left: .15em;
        position: relative;
        top: .15em;
        transform: rotate(-225deg);
        // transform: rotate(-45deg);
        transition: all .3s ease;
        vertical-align: top;
        width: .45em;
      }

      &.down {
        &::before {
          top: 0;
          transform: rotate(-45deg);
          // transform: rotate(-225deg);
        }
      }
    }
  }

  &__copy {
    font-family: 'CharlotteSansMediumPlain';
    padding: 0 20px;
  }

}